<template>
	<div class="mru-details">
		<div class="inputs-container">
			<fieldset>
				<legend>Edit Mru Basic Details</legend>
				<label for="name">Name</label>
				<input type="text" id="name" placeholder="Enter Name" :title="name" v-model="name" />
				<label for="location">Location</label>
				<textarea id="location" placeholder="Enter Location" :title="location" v-model="location"></textarea>
				<div>
					<button class="btn" @click="closeModal()" :title="closeButtonText">{{ closeButtonText }}</button>
					<button class="btn" @click="saveChanges()" title="Save Changes" v-if="changed">Save Changes</button>
				</div>
			</fieldset>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
export default {
	name: "EditMruDetails",
	inheritAttrs: false,
	props: {
		selectedMru: Object,
	},
	data() {
		return {
			body: document.querySelector("body"),
			changed: false,
			closeButtonText: "Close",
			name: this.selectedMru.name,
			location: this.selectedMru.location,
		};
	},
	watch: {
		name() {
			this.changed = true;
		},
		location() {
			this.changed = true;
		},
		changed() {
			this.closeButtonText = this.changed ? "Cancel" : "Close";
		},
	},
	created() {
		let thisInstance = this;
		onBeforeUnmount(() => {
			thisInstance.body.onkeydown = null;
		});
	},
	mounted() {
		let thisInstance = this;
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") thisInstance.closeModal();
		};
	},
	methods: {
		saveChanges() {
			let newMruDetails = this.selectedMru;
			newMruDetails.name = this.name;
			newMruDetails.location = this.location;
			this.eventBus.emit("saveMruDetails", newMruDetails);
		},
		closeModal() {
			this.eventBus.emit("closeMruDetailsModal");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mru-details {
	text-align: left;
	background-color: rgb(0 0 0 / 70%);
	color: #000;
	backdrop-filter: blur(6px);
	box-shadow: 2px 3px 5px 0px hsl(0deg 0% 0% / 70%);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	display: grid;
	height: 100vh;
	width: 100vw;
}

.inputs-container {
	background-color: #222;
	color: #fff;
	margin: auto;
	padding: 30px;
	border-radius: 1em;
	overflow: hidden auto;
}

fieldset {
	display: flex;
	flex-direction: column;
}
</style>
