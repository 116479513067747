<template>
	<div class="mru-details">
		<div class="table-container">
			<button class="btn close" @click="closeModal()" title="Close">Close</button>
			<button class="btn delete" @click="showDeleteModal()" title="Delete This MRU">Delete This MRU</button>
			<h2>{{ selectedMru.name }}</h2>
			<h3 :class="selectedMru.isEnabled ? 'enabled' : 'disabled'">
				{{ selectedMru.isEnabled ? "Enabled" : "Disabled" }}
			</h3>
			<table v-if="Object.keys(selectedMru.mruStatus).length > 0">
				<tr v-for="(value, propertyName) in selectedMru.mruStatus" :key="propertyName">
					<th>{{ propertyName }}</th>
					<td :class="errorStates.includes(value) ? stateClassNames[value] : ''">{{ value }}</td>
				</tr>
			</table>
			<div v-else style="text-align: center">
				<p>Server has not received a check-in from this MRU.</p>
				<p>MRU status is unknown.</p>
			</div>
		</div>
	</div>
	<dialog id="delete-dialog">
		<div>
			<p id="delete-text">
				{{ `Are you sure you want to delete ${this.selectedMru.name}?\nThis action cannot be undone!` }}
			</p>
			<div id="btn-container">
				<button id="confirmButton" class="btn" value="default" @click="deleteMRUModal()">Ok</button>
				<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeDeleteModal()">Cancel</button>
			</div>
		</div>
	</dialog>
</template>

<script>
import { onBeforeUnmount } from "vue";
export default {
	name: "MruStatusInstance",
	inheritAttrs: false,
	props: {
		selectedMru: Object,
	},
	data() {
		return {
			body: document.querySelector("body"),
			errorStates: ["Offline", "NotUsed", "Good", "NeedsAttendant", "Error"],
			stateClassNames: {
				Offline: "offline",
				NotUsed: "not-used",
				Good: "good",
				NeedsAttendant: "needs-attendant",
				Error: "error",
				inputDialog: null,
			},
		};
	},
	created() {
		let thisInstance = this;
		onBeforeUnmount(() => {
			thisInstance.body.onkeydown = null;
		});
	},
	mounted() {
		let thisInstance = this;
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") thisInstance.closeModal();
		};
		this.inputDialog = document.getElementById("delete-dialog");
	},
	methods: {
		closeModal() {
			this.eventBus.emit("closeMruDetailsModal");
		},
		deleteMRUModal() {
			this.eventBus.emit("deleteSelectedMRU", this.selectedMru);
			this.closeDeleteModal();
		},

		showDeleteModal() {
			this.inputDialog.showModal();
		},

		closeDeleteModal() {
			this.inputDialog.close();
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mru-details {
	text-align: left;
	background-color: rgb(0 0 0 / 70%);
	color: #000;
	backdrop-filter: blur(6px);
	box-shadow: 2px 3px 5px 0px hsl(0deg 0% 0% / 70%);
	position: fixed;
	top: 98px;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	display: grid;
	height: calc(100vh - 156px);
	width: 100vw;
}

.table-container {
	position: relative;
	height: 80%;
	width: 90%;
	background-color: #222;
	color: #fff;
	margin: auto;
	padding: 15px;
	border-radius: 1em;
	overflow: hidden auto;
}

.mru-details th {
	overflow-wrap: anywhere;
}

.mru-details td,
.mru-details th {
	border: 1px #555 solid;
	padding: 5px 30px;
}

h2 {
	margin-top: 30px;
}

.enabled {
	color: green;
}

.disabled {
	color: red;
}

.close,
.delete {
	position: absolute;
	top: 10px;
}

.close {
	width: 5em;
	left: 10px;
}

.delete {
	width: 10em;
	right: 10px;
	left: unset;
	background-color: #f00;
	font-weight: bold;
}

.error {
	background-color: #f00;
}

.good {
	background-color: #008000;
}

.not-used {
	color: #555;
}

.needs-attendant {
	color: #000;
	background-color: #ff0;
}

.offline {
	background-color: #000;
	color: #ff0;
}

#delete-dialog {
	margin: auto 15px;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}
::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}
#btn-container {
	display: flex;
	justify-content: center;

	.btn {
		min-width: 6em;
		min-height: 2.5em;
	}
}
#delete-dialog {
		margin: auto;
}
#delete-text {
	text-align: center;
}

@media (min-width: 768px) {
	.table-container {
		width: auto;
		padding: 30px;
	}

	.mru-details th {
		overflow-wrap: unset;
	}

	h2 {
		margin-top: 0.83em;
	}
}
</style>
