<template>
	<div>
		<h1>Mru Administration</h1>
		<div v-if="serverBusy" id="server-activity">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<div id="paging">
			<label for="casinoOptions">Casino</label>
			<select title="Select Casino" id="casinoOptions" v-model="selectedCasinoId">
				<option v-for="(item, index) in casinoList" :key="index" :value="item.id">{{ item.name }}</option>
			</select>
			<button class="btn" @click="getSelectedCasinoMrus()">Refresh MRUs</button>
		</div>
		<small class="status-check-time">MRUs status as of {{ lastStatusCheck.toLocaleTimeString([], dateOptions) }}</small>
		<div v-if="selectedCasinoId && mrusSelectedCasino.length > 0" class="card-container" :class="setMruColumns()">
			<div v-for="(mru, index) in mrusSelectedCasino" :key="index" class="card">
				<MruStatusInstance v-if="mru.hasOwnProperty('mruStatus')" :mru="mru" :index="index" />
			</div>
		</div>
		<div v-else>
			<h3 v-if="selectedCasino?.name">No MRUs are registered for {{ selectedCasino.name }}</h3>
			<h3 v-else>Please select a casino.</h3>
		</div>
		<transition name="fade">
			<component :is="currentModal" :selectedMru="selectedMru" />
		</transition>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import MruStatusInstance from "@/components/MruStatusInstance";
import MruDetails from "@/components/MruDetails";
import EditMruDetails from "@/components/EditMruDetails";

export default {
	name: "MruAdministration",
	components: {
		MruStatusInstance,
		MruDetails,
		EditMruDetails,
	},
	props: {
		adminState: Object,
		casinoList: Array,
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			lastStatusCheck: new Date(),
			busyText: "",
			currentModal: null,
			selectedCasinoId: this.adminState?.casinoId || null,
			selectedCasino: {},
			mruStatus: [],
			selectedMru: {},
			mrusSelectedCasino: [],
			mrusNotCheckedIn: [],
		};
	},
	watch: {
		selectedCasinoId() {
			this.selectedCasino = this.casinoList.filter((casino) => casino.id == this.selectedCasinoId)[0];
			this.getSelectedCasinoMrus();
		},
	},
	created() {
		this.getSelectedCasinoMrus();
		let thisInstance = this;
		this.eventBus.on("updateMruEnabled", (mru) => {
			thisInstance.updateMruEnabled(mru);
		});
		this.eventBus.on("openMruDetailsModal", (mru) => {
			thisInstance.currentModal = "MruDetails";
			thisInstance.selectedMru = mru;
			thisInstance.stopBodyScroll(true);
		});
		this.eventBus.on("openEditMruDetailsModal", (mru) => {
			thisInstance.currentModal = "EditMruDetails";
			thisInstance.selectedMru = mru;
		});
		this.eventBus.on("deleteSelectedMRU", (mru) => {
			thisInstance.deleteSelectedMRU(mru);
		});

		this.eventBus.on("closeMruDetailsModal", () => {
			thisInstance.selectedMru = {};
			thisInstance.currentModal = null;
			thisInstance.stopBodyScroll(false);
		});
		this.eventBus.on("saveMruDetails", (mru) => {
			thisInstance.saveMruBasicDetails(mru);
			thisInstance.selectedMru = {};
			thisInstance.currentModal = null;
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("updateMruEnabled");
			thisInstance.eventBus.off("openMruDetailsModal");
			thisInstance.eventBus.off("openEditMruDetailsModal");
			thisInstance.eventBus.off("deleteSelectedMRU");
			thisInstance.eventBus.off("closeMruDetailsModal");
			thisInstance.eventBus.off("saveMruDetails");
		});
	},
	methods: {
		setMruColumns() {
			let className = "";
			switch (this.mrusSelectedCasino.length) {
				case 1:
					className = "two-col";
					break;
				case 2:
					className = "two-col";
					break;
				case 3:
					className = "three-col";
					break;
				default:
					className = "";
			}
			return className;
		},
		async getSelectedCasinoMrus() {
			this.busyText = "Loading MRUs";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/mru", this.rabbitsfootHostUrl);

			let params = requestUrl.searchParams;
			params.set("casinoId", this.selectedCasinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				if (Array.isArray(dataJson) && dataJson.length === 0) {
					this.status.warn = true;
					this.status.message = `Server returned code ${fetchStatus.code} but returned an empty list.`;
					this.eventBus.emit("updateStatus", this.status);
					this.status.warn = false;
				}

				this.mrusSelectedCasino = dataJson;

				this.serverBusy = false;
				this.busyText = "";
				this.getMruStatusForAllMrus();
			} catch (e) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		mapMruListInfoToMruStatus() {
			this.mrusNotCheckedIn = [];

			let thisInstance = this;
			this.mrusSelectedCasino.forEach((mru) => {
				mru.mruStatus = thisInstance.mruStatus.filter((unit) => unit.mruId === mru.id)[0] || {};
				if (Object.keys(mru.mruStatus).length === 0) thisInstance.mrusNotCheckedIn.push(mru);
			});

			let notCheckedIn = this.mrusNotCheckedIn?.length;

			if (notCheckedIn) {
				let casinoName = this.selectedCasino.name;
				let message = "";

				if (notCheckedIn < this.mrusSelectedCasino.length)
					message = `${notCheckedIn} of the MRUs for ${casinoName} ha${
						notCheckedIn > 1 ? "ve" : "s"
					} not checked in with the server. Status for ${notCheckedIn > 1 ? "these" : "it"} is unknown.`;

				if (notCheckedIn === this.mrusSelectedCasino.length)
					message = `None of the MRUs for ${casinoName} have checked in with the server. Status is unknown.`;

				this.status.warn = true;
				this.status.message = message;
				this.eventBus.emit("updateStatus", this.status);
				this.status.warn = false;
			}
		},
		async getMruStatusForAllMrus() {
			let thisInstance = this;
			this.selectedCasino = this.casinoList.filter((casino) => casino.id == this.selectedCasinoId)[0];

			this.busyText = `Getting last check-in status for MRUs`;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/mru/status", this.rabbitsfootHostUrl);

			let params = requestUrl.searchParams;
			params.set("casinoId", this.selectedCasinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				if (Array.isArray(dataJson) && dataJson.length > 0) {
					dataJson.forEach((item) => {
						item.statusDate = new Date(item.statusDate).toLocaleTimeString([], thisInstance.dateOptions);
						item.lastDoorOpenDate = new Date(item.lastDoorOpenDate).toLocaleTimeString([], thisInstance.dateOptions);
						item.lastStartupDate = new Date(item.lastStartupDate).toLocaleTimeString([], thisInstance.dateOptions);
					});
				}

				this.mruStatus = dataJson;

				this.mapMruListInfoToMruStatus();
				this.lastStatusCheck = new Date();

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		async updateMruEnabled(mru) {
			this.busyText = `${mru.isEnabled ? "Enabling" : "Disabling"} ${mru.name}`;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				Id: mru.id,
				Enable: mru.isEnabled,
			};

			let requestUrl = new URL("/api/v1/mru/enable", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					if (fetchStatus.code === 404) {
						fetchStatus.message = `${mru.name} not found on server. Refreshing MRU list.`;
						this.getSelectedCasinoMrus();
					}
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let thisMruStatus = this.mruStatus.filter((unit) => unit.mruId === mru.id)[0];
				let thisMru = this.mrusSelectedCasino.filter((item) => item.id === mru.id)[0];

				thisMru = await response.json();
				thisMru.mruStatus = thisMruStatus;

				// Updating isEnabled for this.mruListAllCasinos
				let updateThisMruForMainList = this.mrusSelectedCasino.filter((unit) => unit.id === mru.id)[0];
				updateThisMruForMainList.isEnabled = thisMru.isEnabled;

				this.status.ok = true;
				this.status.message = `${mru.name} is ${mru.isEnabled ? "Enabled" : "Disabled"}`;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		async saveMruBasicDetails(mru) {
			this.busyText = `Saving basic details for ${mru.name}`;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				Id: mru.id,
				Name: mru.name,
				Location: mru.location,
			};

			let requestUrl = new URL("/api/v1/mru", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "PUT",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					if (fetchStatus.code === 404) {
						fetchStatus.message = `${mru.name} not found on server. Refreshing MRU list.`;
						this.getSelectedCasinoMrus();
					}
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let thisMruStatus = this.mruStatus.filter((unit) => unit.mruId === mru.id)[0];
				let thisMru = this.mrusSelectedCasino.filter((item) => item.id === mru.id)[0];

				thisMru = await response.json();
				thisMru.mruStatus = thisMruStatus;

				this.status.ok = true;
				this.status.message = `${mru.name} basic details have been saved.`;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateAuditLogs");
				this.serverBusy = false;
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		async deleteSelectedMRU(mru) {
			this.busyText = `Deleting ${mru.name}`;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/mru/${mru.id}`, this.rabbitsfootHostUrl);

			let request = new Request(requestUrl.toString(), {
				method: "DELETE",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					if (fetchStatus.code === 404) {
						fetchStatus.message = `${mru.name} not found on server. Refreshing MRU list.`;
						this.getSelectedCasinoMrus();
					}
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				this.status.message = `${mru.name} deleted.`;
				this.eventBus.emit("updateStatus", this.status);

				this.serverBusy = false;
				this.busyText = "";
				this.getSelectedCasinoMrus();
			} catch (e) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#paging {
	flex-flow: row wrap;
}

#server-activity {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
	top: 0;
	bottom: 0;
	display: grid;
	align-content: center;
	z-index: 1;
}

.card-container {
	display: block;
	padding: 15px;
}

.card {
	position: relative;
	margin: 15px auto;
	padding: 15px;
	background-color: #bccfe5;
	color: #000;
	font-weight: bold;
	border-radius: 0.5em;
	box-shadow: 2px 3px 5px 0px hsl(0deg 0% 0% / 70%);
	user-select: none;
	min-width: calc(100% - 30px);
}

h2.edit {
	margin: 15px;
}

.status-check-time {
	display: block;
	text-align: center;
}

.slidedown-enter-active,
.slidedown-leave-active {
	overflow: hidden;
	max-height: 40em;
	transition: max-height 0.25s ease;
}

.slidedown-enter-from,
.slidedown-leave-to {
	max-height: 0;
}

@media (min-width: 768px) {
	#server-activity {
		width: 80vw;
	}

	.card-container {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
		column-gap: 2%;
	}

	.card-container.two-col {
		grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
	}

	.card-container.three-col {
		grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
	}

	.two-col .card {
		width: 95%;
	}
}

@media (min-width: 1024px) {
	.card-container {
		grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
	}
}
</style>
